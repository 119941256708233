.Loader-outer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Loader-wrapper {
  margin: 40px auto;
  width: 80px;
  height: 80px;
  position: relative;
  transform: rotateZ(45deg);
}

.Loader-wrapper .Loader-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}
.Loader-wrapper .Loader-cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00818a;
  animation: foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.Loader-wrapper .Loader-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.Loader-wrapper .Loader-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.Loader-wrapper .Loader-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.Loader-wrapper .Loader-cube2::before {
  animation-delay: 0.3s;
}
.Loader-wrapper .Loader-cube3::before {
  animation-delay: 0.6s;
}
.Loader-wrapper .Loader-cube4::before {
  animation-delay: 0.9s;
}

@keyframes foldCubeAngle {
  0%,
  10% {
    transform: perspective(280px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(280px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(280px) rotateY(180deg);
    opacity: 0;
  }
}
