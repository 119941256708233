@import url("https://fonts.googleapis.com/css?family=PT+Sans&display=swap");

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "PT Sans", sans-serif;
}

html,
body,
div,
form,
fieldset,
legend,
caption,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre {
  font-size: 100%;
  font-weight: normal;

  margin: 0;
  padding: 0;

  vertical-align: baseline;

  border: 0;
  outline: 0;
  background: transparent;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  vertical-align: top;
}

img {
  border: 0;
}

a,
a:link {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
