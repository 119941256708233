.Header-full {
  display: flex;
  justify-content: space-between;
  background-color: #00818a;
  color: #dbedf3;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Header-title {
  padding: 20px;
  cursor: pointer;
}

.Header-categories {
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
}

.Header-cat-item {
  cursor: pointer;
  padding: 20px;
}

.Header-cat-item_active,
.Header-cat-item:hover {
  background-color: #dbedf3;
  color: #283149;
  font-weight: bold;
}

.Header-small {
  display: none;
  background-color: #00818a;
  justify-content: space-between;
  color: #dbedf3;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Header-small-top {
  display: flex;
  justify-content: space-between;
}

.Header-small-title {
  padding: 20px;
  cursor: pointer;
}

.Header-small-category {
  font-weight: bold;
  padding: 20px;
}

.Header-small-toggle {
  border: 2px dashed white;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.Header-small-categories {
  display: flex;
  flex-direction: column;
}

.Header-small-cat-item {
  cursor: pointer;
  padding: 5px 10px;
}

.Header-small-cat-item_active,
.Header-small-cat-item:hover {
  background-color: #dbedf3;
  color: #283149;
  font-weight: bold;
}

@media all and (max-width: 1024px) {
  .Header-title {
    padding: 10px;
  }

  .Header-cat-item {
    padding: 10px;
  }
}

@media all and (max-width: 768px) {
  .Header-full {
    display: none;
  }
  .Header-small {
    display: block;
  }
}
