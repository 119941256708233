.FeedItem {
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;
  background-color: #00818a;
  color: #dbedf3;
  width: 600px;
  word-wrap: break-word;
  border-radius: 5px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
}

.FeedItem-title {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px 20px;
}

.FeedItem-image {
  max-width: 100%;
}

.FeedItem-image > img {
  width: 100%;
  object-fit: cover;
}

.FeedItem-ingress {
  font-size: 14px;
  padding: 10px 20px;
}

.FeedItem-meta {
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 20px;
}

@media all and (max-width: 768px) {
  .FeedItem {
    width: 100%;
  }
}
