.App-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.App {
  margin-top: 64px;
}
