@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: "PT Sans", sans-serif;
}

html,
body,
div,
form,
fieldset,
legend,
caption,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre {
  font-size: 100%;
  font-weight: normal;

  margin: 0;
  padding: 0;

  vertical-align: baseline;

  border: 0;
  outline: 0;
  background: transparent;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  vertical-align: top;
}

img {
  border: 0;
}

a,
a:link {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.App {
  margin-top: 64px;
}

.FeedItem {
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;
  background-color: #00818a;
  color: #dbedf3;
  width: 600px;
  word-wrap: break-word;
  border-radius: 5px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
}

.FeedItem-title {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px 20px;
}

.FeedItem-image {
  max-width: 100%;
}

.FeedItem-image > img {
  width: 100%;
  object-fit: cover;
}

.FeedItem-ingress {
  font-size: 14px;
  padding: 10px 20px;
}

.FeedItem-meta {
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 20px;
}

@media all and (max-width: 768px) {
  .FeedItem {
    width: 100%;
  }
}

.Header-full {
  display: flex;
  justify-content: space-between;
  background-color: #00818a;
  color: #dbedf3;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Header-title {
  padding: 20px;
  cursor: pointer;
}

.Header-categories {
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
}

.Header-cat-item {
  cursor: pointer;
  padding: 20px;
}

.Header-cat-item_active,
.Header-cat-item:hover {
  background-color: #dbedf3;
  color: #283149;
  font-weight: bold;
}

.Header-small {
  display: none;
  background-color: #00818a;
  justify-content: space-between;
  color: #dbedf3;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Header-small-top {
  display: flex;
  justify-content: space-between;
}

.Header-small-title {
  padding: 20px;
  cursor: pointer;
}

.Header-small-category {
  font-weight: bold;
  padding: 20px;
}

.Header-small-toggle {
  border: 2px dashed white;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

.Header-small-categories {
  display: flex;
  flex-direction: column;
}

.Header-small-cat-item {
  cursor: pointer;
  padding: 5px 10px;
}

.Header-small-cat-item_active,
.Header-small-cat-item:hover {
  background-color: #dbedf3;
  color: #283149;
  font-weight: bold;
}

@media all and (max-width: 1024px) {
  .Header-title {
    padding: 10px;
  }

  .Header-cat-item {
    padding: 10px;
  }
}

@media all and (max-width: 768px) {
  .Header-full {
    display: none;
  }
  .Header-small {
    display: block;
  }
}

.Loader-outer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Loader-wrapper {
  margin: 40px auto;
  width: 80px;
  height: 80px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.Loader-wrapper .Loader-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.Loader-wrapper .Loader-cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00818a;
  -webkit-animation: foldCubeAngle 2.4s infinite linear both;
          animation: foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.Loader-wrapper .Loader-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.Loader-wrapper .Loader-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.Loader-wrapper .Loader-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.Loader-wrapper .Loader-cube2::before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.Loader-wrapper .Loader-cube3::before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.Loader-wrapper .Loader-cube4::before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(280px) rotateX(-180deg);
            transform: perspective(280px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(280px) rotateX(0deg);
            transform: perspective(280px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(280px) rotateY(180deg);
            transform: perspective(280px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(280px) rotateX(-180deg);
            transform: perspective(280px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(280px) rotateX(0deg);
            transform: perspective(280px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(280px) rotateY(180deg);
            transform: perspective(280px) rotateY(180deg);
    opacity: 0;
  }
}

